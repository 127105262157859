import {Inject, Injectable} from '@angular/core';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import {BaseService} from './base.service';
import {HttpClient} from '@angular/common/http';
import {merge} from 'lodash';

export interface Parcel {
      parcel_id: string;
      mun_gis: string;
      plan_name: string;
      fy_no: string;
      pid: string;
      neighborhood: string;
      rec_id: string;
      land_area: string;
      land_source: string;
      lasso_id: string;
      cvg_tax_code: string;
      land_use_zone: string;
      land_use_zone_source: string;
      has_improvement: string;
      appraisal_status: string;
      pin: string;
      wetlands: string;
      flood_area: string;
      land_value: string;
      building_value: string;
      municipal: string;
      site: string;
      geometry: any;
      walters_source: string;
}

@Injectable({providedIn: 'root'})
export class ParcelService extends BaseService {
  public meta: any = {
    verbose_name: 'Additional Parcels',
    type: 'parcels',
    foreign_key_field: 'site',
    display_fields: [
      'parcel_id', 'walters_source', 'mun_gis',  'plan_name', 'fy_no', 'pid', 'neighborhood', 'rec_id', 'land_area',
      'cvg_tax_code', 'land_use_zone', 'has_improvement', 'appraisal_status', 'pin', 'wetlands', 'flood_area',
      'land_value', 'building_value', 'municipal', 'last_edited_date'
    ],
    fields: {
      parcel_id: {
        get_choices: (s) => this.search(s),
        select_choice: (s, i) => this.convertParcelToWALTERS(s, i),
        choices: null
      }
    }
  }
  public layer = null;
  public loading = false;

  constructor(http: HttpClient, @Inject('environment') private environment) {
    super(`${environment.baseUrl}/parcels`, http);
    this.layer = new FeatureLayer({url: environment.parcelLayerService});
  }

  private convertParcelToWALTERS(selected, item): Parcel {
    merge(item, {
      parcel_id: selected.attributes.Parcel_Sea,
      mun_gis: selected.attributes.MUN_GIS,
      plan_name: selected.attributes.PlanNAME,
      fy_no: selected.attributes.FY_No,
      pid: selected.attributes.PID,
      neighborhood: selected.attributes.Neighborho,
      rec_id: selected.attributes.RecID,
      land_area: selected.attributes.LandArea,
      land_source: selected.attributes.LandSource,
      lasso_id: selected.attributes.LassoID,
      cvg_tax_code: selected.attributes.CVGTaxCode,
      land_use_zone: selected.attributes.LandUseZon,
      land_use_zone_source: selected.attributes.LandUseZ_1,
      has_improvement: selected.attributes.HasImprove,
      appraisal_status: selected.attributes.AppraisalS,
      pin: selected.attributes.PIN,
      wetlands: selected.attributes.Wetlands,
      flood_area: selected.attributes.FloodArea,
      land_value: selected.attributes.Tax93Value,
      // building_value: selected.attributes.Building_Value,
      // municipal: selected.attributes.Municipal,
      geometry: selected.geometry,
      walters_source: ''
    });
    return item;
  }

  public queryParcelLayer(whereStatement, geometry?): Promise<any[]> {
    return new Promise(resolve => {
      const query = this.layer.createQuery();
      query.outFields = ['*'];
      query.outSpatialReference = {wkid: 4326};
      if (whereStatement !== undefined) {
        query.where = whereStatement;
      } else if (geometry !== undefined) {
        query.geometry = geometry;
        query.spatialRelationship = 'intersects';
      }
      if (query.where || query.geometry) {
        this.layer.queryFeatures(query).then(featureSet => {
          resolve(featureSet.features);
        });
      } else {
        resolve([]);
      }
    });
  }

  public search(parcelId): Promise<Parcel[]> {
    return this.queryParcelLayer(`Parcel_Sea like '%${parcelId}%'`).then(features => {
      return features.map(f => {
        f.display_name = f.attributes.Parcel_Sea;
        return f;
      })
    });
  }
}
