import {EventEmitter, Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {merge} from 'lodash';
import {of, Subject} from "rxjs";
import {finalize, switchMap, tap} from 'rxjs/operators';
import {BaseService} from './base.service';


@Injectable({providedIn: 'root'})
export class SitesService extends BaseService {
  public all_interest_options: {id: string, label: string; ticked?: boolean}[] = [
    {
      label: 'Beaches',
      id: 'beach'
    },
    {
      label: 'Brownfields',
      id: 'brownfields'
    },
    {
      label: 'Emergency Response',
      id: 'er_site'
    },
    {
      label: 'FUDS',
      id: 'fuds'
    },
    {
      label: 'One Stop',
      id: 'onestop'
    },
    {
      label: 'Pre-CERCLIS',
      id: 'precerclis'
    },
    {
      label: 'Solid Waste',
      id: 'solidwaste'
    },

    {
      label: 'Standby Generators',
      id: 'standby'
    },
    {
      label: 'Storage Tanks',
      id: 'ust'
    },
    {
      label: 'Tier II',
      id: 'tierii'
    }
  ];
  meta: {};
  loading = false;
  sites: any[];
  totalPages = 0;
  totalItems = 0;
  currentPage = 1;
  pages = [];
  activeFilters = [];

  public searchParam = {
    search: '',
    ordering: '',
    interests: [],
    page: undefined,
    edit_start: undefined,
    edit_end: undefined,
    village: undefined,
    island: undefined,
    needs_review: undefined,
    precerclis_exposure: undefined,
    one_stop_permit_type: undefined,
    ust_past_due_actions: undefined,
    solidwaste_complaint_status: undefined,
    precerclis_circumstance: undefined,
    beaches_under_advisory: undefined
  };
  public interestOptions = this.all_interest_options;
  filters: string[];
  sitesService: BaseService;
  filterParams: Subject<any> = new Subject<any>();

  constructor(http: HttpClient, private route: ActivatedRoute, private router: Router,
              @Inject('environment') private environment) {
    super(`${environment.baseUrl}/sites`, http);
    this.filterParams.pipe(
      switchMap(filterParams => this.updateSearch(filterParams))
    ).subscribe()
  }

  // var abort = $q.defer();


  public clearFilterParams() {
    this.searchParam.interests = [];
    this.searchParam.edit_start = undefined;
    this.searchParam.edit_end = undefined;
    this.searchParam.village = undefined;
    this.searchParam.precerclis_exposure = undefined;
    this.searchParam.precerclis_circumstance = undefined;
    this.searchParam.solidwaste_complaint_status = undefined;
    this.searchParam.needs_review = undefined;
    this.searchParam.beaches_under_advisory = undefined;
    this.searchParam.ust_past_due_actions = undefined;
    this.searchParam.one_stop_permit_type = undefined;
  }

  public setOrderingParams(filterParam) {
    let index;
    if (filterParam.charAt(0) === '-') {
      index = this.filters.indexOf(filterParam.slice(1, filterParam.length));
    } else {
      index = this.filters.indexOf('-' + filterParam);
    }

    if (index > -1) {
      this.filters.splice(index, 1, filterParam);
    } else {
      this.filters.push(filterParam);
    }
    this.searchParam.ordering = this.filters.join(',');
    const queryParams = merge(this.route.snapshot.params, this.searchParam);
    this.router.navigate(['site', 'list'], {queryParams});
    // this.search().subscribe();
  }

  public removeOrderingParam(filterParam) {
    let index = this.filters.indexOf(filterParam);

    if (index === -1) {
      index = this.filters.indexOf('-' + filterParam);
    }

    if (index > -1) {
      this.filters.splice(index, 1);
    }
    this.searchParam.ordering = this.filters.join(',');
    const queryParams = merge(this.route.snapshot.params, this.searchParam);
    this.router.navigate(['site', 'list'], {queryParams});
    // this.search().subscribe();
  }

  public search() {
    this.filters = this.route.snapshot.queryParams.ordering ? this.route.snapshot.queryParams.ordering.split(',') : [];

    // if (typeof this.searchParam.interests === 'string') {
      this.searchParam.interests = [];
    // }
    // if ($location.search().ordering === undefined) $location.search('ordering', '-last_edited_date');

    Object.keys(this.route.snapshot.queryParams).forEach(key => {
      if (key === 'dataset') {
        if (this.searchParam.interests.indexOf(this.route.snapshot.queryParams[key]) < 0) {
          this.searchParam.interests.push(this.route.snapshot.queryParams[key]);
        }

        // this.interestOptions = this.all_interest_options.filter(v => v.id !== this.route.snapshot.queryParams[key]);
      } else if (key !== 'search') {
        this.searchParam[key] = this.route.snapshot.queryParams[key];
        // this.setActiveFilter(key, this.route.snapshot.queryParams[key]);
      } else {
        this.searchParam[key] = this.route.snapshot.queryParams[key];
      }
    });


    // if ($window.location.href.indexOf("/site/list/") < 0) {
    //     // $window.location.href = '/site/list/?q=' + this.searchParam.search
    // } else {
    // if (this.searchParam.search === undefined && $window.searchParam !== '') {
    //     this.searchParam.search = $location.search().q;
    // } else {
    //     $window.searchParam = this.searchParam.search;
    // }

    this.loading = true;
    this.sites = [];

    if (typeof this.searchParam.page === 'undefined') {
      this.pages = [];
    }

    // get current sorting
    // this.searchParam.ordering = this.filters.join(',');

    if (this.searchParam.interests !== undefined && typeof this.searchParam.interests !== 'string') {
      this.searchParam.interests = this.searchParam.interests.join(',') as any;
    }

    const filteredParams = Object.keys(this.searchParam)
      .filter(key => this.searchParam[key])
      .reduce((obj, key) => {
        obj[key] = this.searchParam[key];
        return obj;
      }, {});
    this.filterParams.next(filteredParams);
    return of();
  }
  private updateSearch(filterParams) {
    return this.getList<any>(filterParams).pipe(tap(response => {
        this.sites = response.results;
        this.meta = response.meta;
        this.totalPages = response.meta.pages;
        this.totalItems = response.meta.count;
        this.currentPage = response.meta.page;

        if (this.totalPages === this.currentPage) {
          this.sites.push({
            globalid: 'new',
            last_edited_date: '',
            name: 'Click to add new site'
          });
        }

        // this.pages = [];
        // for (var i = 1; i < this.totalPages + 1; i++) {
        //   if (this.currentPage === i) {
        //     this.pages.push({id: i, class: "active"});
        //   }
        //   else {
        //     this.pages.push({id: i, class: ""});
        //   }
        //
        // }
        this.loading = false;
        // angular.forEach(this.searchParam, function (value, key) {
        //   $location.search(key, value);
        // });
        this.clearFilterParams();
        this.searchParam.page = undefined;
      })
    );
  }

  private _search(text) {
    this.loading = true;
    return this.getList<any>({search: text}).pipe(
      tap(response => {
        this.sites = response.results;
        this.meta = response.meta;
        return response;
      }),
      finalize(() => this.loading = false)
    ).subscribe();
  }
}
