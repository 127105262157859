import {Component, OnInit, Input, Output, OnChanges, SimpleChanges, EventEmitter} from '@angular/core';
import {SitesService} from '../services/sites.service';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-table-sort-order-v2',
  templateUrl: './table-sort-order-v2.component.html',
  styleUrls: ['./table-sort-order-v2.component.css'],
})
export class TableSortOrderV2Component implements OnInit, OnChanges{
  public sortStatus = null;
  @Input() field: string;
  private ordering;
  @Output() orderingChange: EventEmitter<any> = new EventEmitter<any>();

  // @Input() filters: any;
  @Output() search: EventEmitter<any> = new EventEmitter<any>();

  constructor(public sites: SitesService, private route: ActivatedRoute, private router: Router) {

  }

  ngOnInit(): void {
    this.ordering = this.route.snapshot.queryParams.ordering ?
    this.route.snapshot.queryParams.ordering.length > 0?
      [...this.route.snapshot.queryParams.ordering.split(',')]:[]:[];
    this.checkSortStatus();
  }
  ngOnChanges(changes: SimpleChanges) {
    if (changes.ordering) {
      console.log('ngOnChanges table sort order v2');
      this.checkSortStatus();
    }
  }

  private checkSortStatus() {
    // this.ordering = this.ordering?this.ordering:[];
   if (this.ordering.indexOf('-' + this.field) > -1) {
      this.sortStatus = 'desc';
    }else if (this.ordering.indexOf(this.field) > -1) {
      this.sortStatus = 'asc';
    } else {
      this.sortStatus = null;
      // this.sortOrder = null;
    }
  }
  private setOrderingParams(filterParam) {
      if (this.ordering.length > 0) {
          this.ordering.splice(0, 1, filterParam);
      } else {
          this.ordering.push(filterParam);
      }
      this.search.emit();
  }
  private removeOrderingParam(filterParam) {
      let index = this.ordering.indexOf(filterParam);

      if (index === -1) {
          index = this.ordering.indexOf('-' + filterParam);
      }
      if (index > -1) {
          this.ordering.splice(index, 1);
      }
      this.search.emit();
  }
  public changeSort () {
      if (this.sortStatus === null) {
          this.setOrderingParams(this.field);
      } else if (this.sortStatus === 'asc') {
          this.setOrderingParams('-' + this.field);
      } else {
          this.setOrderingParams(this.field);
      }
      this.checkSortStatus();
      this.orderingChange.emit([...this.ordering]);

      const newParams = {...this.route.snapshot.queryParams};
      newParams.ordering = this.ordering.length>0?this.ordering.join(','):[];
      this.router.navigate([], {queryParams: newParams});
  }
  public clearSort () {
      this.removeOrderingParam(this.field);
      this.orderingChange.emit([...this.ordering]);
      this.checkSortStatus();
      const newParams = {...this.route.snapshot.queryParams};
      newParams.ordering = this.ordering.length>0?this.ordering.join(','):[];
      this.router.navigate([], {queryParams: newParams});
  }
}

