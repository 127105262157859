import {Component, Inject, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {DatePipe} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {forkJoin} from 'rxjs';
import {first, switchMap, take, tap} from 'rxjs/operators';
import {merge} from 'lodash';
import {BaseService} from '../services/base.service';
import {SitesService} from '../services/sites.service';
import {DisplayPipe} from "../pipes/display.pipe";


@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css'],
  providers: [DatePipe, DisplayPipe]
})
export class SearchComponent implements OnInit {
  @Input() showFilter: any;
  @Input() searchText: string;
  public currentDataset: string;
  public filter: { [key: string]: any } = {isopen: false};
  location: Location;
  villages: any[];
  filterSettings: any;
  public date_format = 'yyyy-MM-dd HH:mm';

  beaches_under_advisory_choices: any;
  precerclis_exposures: any;
  solidwaste_complaint_status: any;
  precerclis_circumstances: any;
  one_stop_permit_types: any;
  oane_stop_permit_types: any;

  public dateOptions = {
    showWeeks: false,
    showButtonBar: false
  };
  public open = {};

  urlParams: URLSearchParams;
  precerclisService: BaseService;
  solidwastePermitsService: BaseService;
  onestopPermitsService: BaseService;
  public sitesService: BaseService;

  constructor(http: HttpClient, public sites: SitesService, private datePipe: DatePipe,
              private displayPipe: DisplayPipe, public router: Router, public activatedRoute: ActivatedRoute,
              @Inject('environment') private environment) {
    this.precerclisService = new BaseService(`${environment.baseUrl}/precerclis/sites`, http);
    this.solidwastePermitsService = new BaseService(`${environment.baseUrl}/solidwaste/permits`, http);
    this.onestopPermitsService = new BaseService(`${environment.baseUrl}/onestop/permits`, http);
    this.sitesService = new BaseService(`${environment.baseUrl}/sites`, http);
  }

  ngOnInit() {
    // this.urlParams = new URLSearchParams(window.location.search);
    // this.currentDataset = this.urlParams.get('dataset');
    //
    // this.location = window.location;
    this.sitesService.get<any>('metadata').pipe(tap(response => {
      this.villages = response.fields.village.all_choices;
      // set any active filters on load
      this.sites.activeFilters = [];
      Object.keys(this.activatedRoute.snapshot.params).forEach(key => {
        if (this.activatedRoute.snapshot.params[key]) {
          const value = this.activatedRoute.snapshot.params[key]
          key = key === 'interests' ? 'selectedInterests' : key;
          this.setActiveFilter(key, value);
        }
      });
    })).subscribe();

    this.sites.interestOptions.map(item => {
      item.ticked = false;
    });
    // this.filter.selectedInterests = [];
    // this.sites.activeFilters = [];

    // this.sites.searchParam.search = $state.params.search;
    this.get_one_stop_permit_type_choices();
    this.get_precerclis_exposure_choices();
    this.get_solidwaste_statuses();
    this.sites.search().pipe(take(1)).subscribe();

    this.filterSettings = {
      smartButtonMaxItems: 10,
      smartButtonTextConverter: (itemText, originalItem) => itemText
    };

    this.beaches_under_advisory_choices = [{display_name: 'Yes', value: true}, {
      display_name: 'No',
      value: false
    }];
  }


  setActiveFilter(key, value) {
    if ((key === 'selectedInterests' || key === 'dataset') && value) {
      if (Array.isArray(value)) {
        value.forEach(v => {
          this.sites.searchParam.interests.push(v);
          this.sites.searchParam.interests.push(v);
          const interest = this.sites.interestOptions.find(f => f.id === v);
          this.sites.activeFilters.push({type: key, display: interest.label, id: interest.id});
        });
      } else if (typeof value === 'string') {
        value = value.split(',');
        this.filter.selectedInterests = value;
        value.forEach(v => {
          v = this.sites.interestOptions.find(x => x.id === v);
          if (v) {
            this.sites.activeFilters.push({type: key, display: v.label, id: v.id});
            const index = this.sites.interestOptions.indexOf(v);
            this.sites.interestOptions[index].ticked = true;
          }
        });
      }
    } else {
      this.filter[key] = value;
    }

    if (key === 'edit_start' && value) {
      if (typeof value === 'string') {
        value = new Date(value);
      }
      this.sites.searchParam.edit_start = value.toISOString();
      this.filter[key] = value;
      this.sites.activeFilters.push({
        type: key,
        display: 'Last Edited After: ' + this.datePipe.transform(this.filter.edit_start, 'shortDate')
      });
    }
    if (key === 'edit_end' && value) {
      if (typeof value === 'string') {
        value = new Date(value);
      }
      this.sites.searchParam.edit_end = value.toISOString();
      this.filter[key] = value;
      this.sites.activeFilters.push({
        type: key,
        display: 'Last Edited Before: ' + this.datePipe.transform(this.filter.edit_end, 'shortDate')
      });
    }
    if (key === 'solidwaste_complaint_type' && value) {
      this.sites.activeFilters.push({
        type: key,
        display: 'Complaint Type: ' + value
      });
    }
    if (key === 'solidwaste_complaint_date_after' && value) {
      if (typeof value === 'string') {
        value = new Date(value);
      }
      this.sites.activeFilters.push({
        type: key,
        display: 'Complaint Received After: ' + this.datePipe.transform(value, 'shortDate')
      });
    }
    if (key === 'solidwaste_complaint_date_before' && value) {
      if (typeof value === 'string') {
        value = new Date(value);
      }
      this.sites.activeFilters.push({
        type: key,
        display: 'Complaint Received Before: ' + this.datePipe.transform(value, 'shortDate')
      });
    }
    if (key === 'village' && value) {
      this.sites.searchParam.village = value;
      this.sites.activeFilters.push({
        type: key,
        display: this.displayPipe.transform(value, this.villages)
      });
    }
    if (key === 'precerclis_exposure' && value) {
      this.sites.searchParam.precerclis_exposure = value;
      this.sites.activeFilters.push({
        type: key,
        display: this.displayPipe.transform(this.filter.precerclis_exposure, this.precerclis_exposures)
      });
    }
    if (key === 'precerclis_circumstance' && value) {
      this.sites.searchParam.precerclis_circumstance = value;
      this.sites.activeFilters.push({
        type: key,
        display: this.displayPipe.transform(this.filter.precerclis_circumstance, this.precerclis_circumstances)
      });
    }
    if (key === 'solidwaste_complaint_status' && value) {
      return this.get_solidwaste_statuses().pipe(tap(() => {
        this.sites.searchParam.solidwaste_complaint_status = value;
        this.sites.activeFilters.push({
          type: key,
          display: `Complaint Status: ${this.displayPipe.transform(this.filter.solidwaste_complaint_status, this.solidwaste_complaint_status)}`
        });
      })).toPromise();
    }
    if (key === 'needs_review' && value) {
      this.sites.searchParam.needs_review = value;
      this.sites.activeFilters.push({type: key, display: 'Needs Review'});
    }
    if (key === 'beaches_under_advisory' && value) {
      this.sites.searchParam.beaches_under_advisory = value;
      if (value === true) {
        this.sites.activeFilters.push({type: key, display: 'Under Advisory'});
      } else if (value === false) {
        this.sites.activeFilters.push({type: key, display: 'Not Under Advisory'});
      }
    }
    if (key === 'ust_past_due_actions' && value) {
      this.sites.searchParam.ust_past_due_actions = value;
      this.sites.activeFilters.push({type: key, display: 'Past Due Enforcement Actions'});
    }
    if (key === 'one_stop_permit_type' && value) {
      return this.get_one_stop_permit_type_choices().then(() => {
        this.sites.searchParam.one_stop_permit_type = value;
        this.sites.activeFilters.push({
          type: key,
          display: this.displayPipe.transform(this.filter.one_stop_permit_type, this.one_stop_permit_types)
        });
      });
    } else if (key === 'one_stop_permit_type') {
      this.sites.searchParam.one_stop_permit_type = undefined;
    }
  }


  public applyFilter() {
    // this.sites.clearFilterParams();

    //                this.sites.activeFilters = [];
    this.sites.searchParam.interests = [];
    this.sites.activeFilters = [];
    const promises = [];
    Object.keys(this.filter).forEach(key => {
      this.setActiveFilter(key, this.filter[key]);
    });
    if (this.filter.hasOwnProperty('selectedInterests')) {
      this.filter.dataset = this.filter.selectedInterests.join(',');
      this.filter.interests = this.filter.selectedInterests.join(',');
    }
    if (this.filter.hasOwnProperty('edit_start') && this.filter.edit_start) {
      this.filter.edit_start = this.filter.edit_start.toISOString();
    }
    if (this.filter.hasOwnProperty('edit_end') && this.filter.edit_end) {
      this.filter.edit_end = this.filter.edit_end.toISOString();
    }
    // forkJoin(promises).pipe(tap(() => {
    // Object.keys(this.sites.searchParam).forEach(key => {
    //   const params = {};
    //   params[key] = this.sites.searchParam[key];
    this.router.navigate(['site', 'list'], {queryParams: this.sites.searchParam, queryParamsHandling: 'merge'});
    // this.$state.transitionTo('root.site.list', this.filter, {notify: false, reload: false});
    // });
    this.filter.isopen = false;
    // return this.sites.search();
    // })).subscribe();
  }

  public clearSelectedFilter(filter) {
    if (filter.type === 'selectedInterests' || filter.type === 'dataset') {
      let item = this.filter.selectedInterests.find(f => f === filter.id);
      let index = this.filter.selectedInterests.indexOf(item);
      this.filter.selectedInterests.splice(index, 1);
      item = this.sites.interestOptions.find(f => f.id === filter.id);
      index = this.sites.interestOptions.indexOf(item);
      this.sites.interestOptions[index].ticked = false;
    } else if (filter.type === 'needs_review') {
      this.filter[filter.type] = null;
    } else if (filter.type === 'beaches_under_advisory') {
      this.filter[filter.type] = null;
    } else {
      this.filter[filter.type] = '';
    }
    this.applyFilter();
  }

  public clearFilters() {
    this.sites.loading = true;
    // this.sites.activeFilters = [];

    this.sites.clearFilterParams();
    this.filter.selectedInterests = [];
    this.filter.edit_start = '';
    this.filter.edit_end = '';
    this.filter.village = '';
    this.filter.island = '';
    this.filter.precerclis_exposure = '';
    this.filter.precerclis_circumstance = '';
    this.filter.solidwaste_complaint_status = '';
    this.filter.needs_review = null;
    this.filter.beaches_under_advisory = null;
    this.filter.one_stop_permit_type = '';

    this.sites.interestOptions.forEach((relatedOption, index) => {
      this.sites.interestOptions[index].ticked = false;
    });
    this.sites.activeFilters = [];
    this.applyFilter();
  }

  public clearTextSearch() {
    this.activatedRoute.snapshot.params.search = '';
    this.router.navigate(['site', 'list'], {queryParams: {search: ''}, queryParamsHandling: 'merge'});
    // this.$state.transitionTo('root.site.list', this.$state.params, {notify: false, reload: false});
    // this.sites.search().subscribe();
  }

  public search() {
    if (!this.router.url.includes('/site/list')) {
      this.router.navigate(['site','list'], {queryParams: {search: this.sites.searchParam.search}});
    }
    const newParams = {...this.activatedRoute.snapshot.params, ...this.sites.searchParam};
    this.router.navigate(['site', 'list'], {queryParams: newParams, queryParamsHandling: 'merge'});
    // this.$state.transitionTo('root.site.list', newParams, {notify: false, reload: false});
    // this.sites.search().subscribe();
  }

  public openCalendar(e, date) {
    this.open[date] = true;
  }

  public get_precerclis_exposure_choices() {
    return this.precerclisService.get<any>('metadata').pipe(tap(response => {
      this.precerclis_exposures = response.fields.exposurepathway.choices;
      this.precerclis_circumstances = response.fields.circumstances.choices;
    }));
  }

  public get_solidwaste_statuses() {
    return this.solidwastePermitsService.get<any>('metadata').pipe(tap(response => {
      this.solidwaste_complaint_status = response.fields.status.choices;
    }));
  }


  public get_one_stop_permit_type_choices() {
    return new Promise(resolve => {
      if (this.one_stop_permit_types !== undefined) {
        resolve();
      } else {
        this.onestopPermitsService.get<any>('metadata').pipe(tap(response => {
          this.oane_stop_permit_types = response.fields.type.choices;
          resolve();
        }));
      }
    });
  }
}
